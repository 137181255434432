/*--------------------------
    About Styles  
---------------------------*/
.about-style-4 {
   
    .title {
        font-weight: 600;
        margin-top: 35px;
        strong {
            color: var(--color-primary);
        }
    }

    .title.second {
        color: black !important;
        font-weight: 500;
        font-style: italic;
    }

    .feature-list {
        width: 420px;
        padding: 0;
        margin: 3em auto;
        @media (max-width: 440px) {
            width: 75%;
        }
        li {
            list-style: disc;
            color: black;
            font-weight: 600;
            .title-wrapper {
                margin-left: 13px;
                .title {
                    font-size: 18px;
                    margin: 0;
                  
                }
                .text {
                    font-size: 16px;
                    color: black !important;
                    font-weight: 500;
                    margin-bottom: 10px;
                    max-width: 80%;
                    margin-top: 13px;
                }
            }
        }
    }
}

.texts {
    li {
        margin: 0;
        color: white;
    }
}


.split-wrapper {
    border-radius: 30px;
    //background: rgba(0,0,0,0.1);
   

    .split-inner {
        h4, p {
            color: black !important;
        }

        .description {
            color: black;
            font-weight: 600;
        }

        p {
            font-weight: 600;
        }
    }
    
    
}

.thumbnail.image-left-content {
    img {
        border-radius: 30px !important;
        height: 478px;
        width: 765px;

        @media (max-width: 1199px) {
            width: 100% !important;
        }

        @media (max-width: 450px) {
            height: 300px;
        }
    }
}

.home-logo {
    width: 350px;
    height: 150px;
    margin: auto;
    position: relative;
    bottom: 100px;
}

.lists {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// About Styles 5 

.about-style-5 {
    position: relative;
    z-index: 2;
    .about-wrapper {
        margin-top: -150px;
        background: var(--color-blackest);
        border-radius: 10px;
        padding: 15px;
        @media #{$md-layout} {
            margin-top: 80px;
            padding: 15px 15px 30px 15px;
        }
        @media #{$sm-layout} {
            margin-top: 80px;
            padding: 15px 15px 30px 15px;
        }
        .thumbnail {
            img {
                border-radius: 10px;
            }
        }
        .content {
            .inner {
                padding: 0 40px;
                @media #{$sm-layout} {
                    padding: 0;
                }
                .title {
                    font-size: 30px;
                    margin-bottom: 13px;
                }
                p {
                    margin-bottom: 20px;
                    width: 80%;
                }
            }
        }
    }
}


.contact-address {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        font-size: 16px;
        svg {
            color: var(--color-primary);
            margin-right: 10px;
        }
    }
}


// About Styles 6
.about-style-6 {

    .rbt-progress-style-1,
    .wrapper {
        position: relative;
        z-index: 2;
        max-width: 850px;
        margin: 0 auto;
        border-radius: 10px;
    }
    .wrapper {
        padding: 50px;
        @media #{$md-layout} {
            padding: 20px;
        }
        @media #{$sm-layout} {
            padding: 20px;
        }
        &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            background: var(--color-lessdark);
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.6;
            border-radius: 10px;
        }

    }

    

    .thumbnail {
        img {
            max-height: 200px;
        }
    }

    .subtitle {
        font-size: 18px;
        font-weight: 500;
    }
    .maintitle {
        font-size: 30px;
        line-height: 1.3;
        margin-top: 17px;
        margin-bottom: 25px;
    }
}

